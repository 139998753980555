import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "wrapper", "overlay", "sidebar" ]

  connect() {
    this.wrapperTarget.classList.add("hidden") 
  }

  open(event) {
    this.wrapperTarget.classList.remove("hidden")
  }

  close(event) {
    this.wrapperTarget.classList.add("hidden")
  }
}
